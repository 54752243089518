import React, { useContext } from 'react';
import { GlobalStateContext } from '../context/GlobalStateContext';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton'; 

const Header = () => {
  const { user } = useContext(GlobalStateContext);

  return (
    <header className="bg-blue-500 text-white p-2 sm:p-4"> {/* Increased padding for larger screens */}
      <nav className="container mx-auto flex flex-col sm:flex-row justify-between items-center relative">
        {/* Title */}
        <h1 className="text-lg sm:text-xl font-bold">Crypto Dashboard</h1>

        {/* Centered Welcome Text - Hidden on small screens */}
        <div className="absolute sm:static left-1/2 transform sm:transform-none -translate-x-1/2 sm:translate-x-0 mt-2 sm:mt-0 hidden sm:block"> {/* Hidden on small screens */}
          <h1 className="text-center text-sm sm:text-base">Welcome, {user ? user.name : 'Guest'}</h1>
        </div>

        {/* Navigation Links */}
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 mt-2 sm:mt-0">
          <Link to="/" className="mr-0 sm:mr-4 text-sm sm:text-base">Home</Link>
          <Link to="/cryptos" className="mr-0 sm:mr-4 text-sm sm:text-base">Cryptos</Link>
          <Link to="/portfolio" className="mr-0 sm:mr-4 text-sm sm:text-base">Portfolio</Link>

          {/* Show LogoutButton only when user is logged in */}
          {user ? <LogoutButton /> : (
            <Link
              to="/login"
              className="bg-white text-blue-500 py-1 px-3 sm:py-2 sm:px-4 rounded hover:bg-gray-100 transition-all duration-300 text-sm sm:text-base"
            >
              Login
            </Link>
          )}
        </div>
      </nav>
    </header>
  );
};

export default Header;

